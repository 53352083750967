<template>
  <div class="content">
    <!-- 网络故障 -->
    <div class="item">
      <h3 class="tit">工单量统计</h3>
      <div class="search">
        <div class="left">
          <span>机房：</span>
          <el-cascader
            @change="dispatch"
            v-model="paramsWorkOrder.idcpName"
            :options="depOptions"
            :props="{
              multiple: true,
              expandTrigger: 'hover',
              label: 'dept_name',
              value: 'dept_name',
              children: 'children',
            }"
            collapse-tags
            clearable
          ></el-cascader>
        </div>
        <div class="right">
          <p v-for="item in tab" :key="item.code" @click="changeWorkOrderTab(item)">
            {{ item.value
            }}<span class="line" v-if="paramsWorkOrder.timeDimension == item.code"></span>
          </p>
        </div>
      </div>
      <div v-loading="workOrderLoading" ref="chartWorkOrder" class="chart-container"></div>
    </div>
    <el-dialog
      title="自定义日期范围"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="600px"
      center
    >
      <div style="display:flex;justify-content: center;padding:20px 0 50px">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import { dispatch } from '@/api/statementStatistics';
import { deptTree } from '@/api/monitor';
export default {
  name: 'serverDecommissioning',
  data() {
    return {
      type: '',
      dateRange: [], //日期范围
      dialogVisible: false,
      workOrderLoading: false,
      depOptions: [], //一级机房数据
      tab: [
        {
          code: 'month',
          value: '月维度',
        },
        {
          code: 'week',
          value: '周维度',
        },
        {
          code: 'custom',
          value: '自定义',
        },
      ],
      paramsWorkOrder: {
        //工单量查询参数
        ticketType: 3,
        idcpName: [], //机房
        timeDimension: 'month', //tab筛选
      },
    };
  },
  mounted() {
    this.deptTree();
    this.dispatch();
  },
  methods: {
    //关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    //一级机房数据
    deptTree() {
      deptTree().then((res) => {
        this.depOptions = res?.data?.data;
      });
    },
    //工单量数据
    dispatch() {
      this.workOrderLoading = true;
      let data = JSON.parse(JSON.stringify(this.paramsWorkOrder));
      data.idcpName = this.paramsWorkOrder.idcpName.map(
        (subArray) => subArray[subArray.length - 1]
      );
      if (data.timeDimension == 'custom') {
        data.startDate = this.dateRange[0];
        data.endDate = this.dateRange[1];
      }
      dispatch(data)
        .then((res) => {
          this.workOrderLoading = false;
          let params = {
            Xdata: res.data.data.map((item) => item.timePoint), //横坐标展示
            dispatchData: res.data.data.map((item) => item.dispatch), //派单量
            completeData: res.data.data.map((item) => item.complete), //完单量
            overtimeData: res.data.data.map((item) => item.overtime), //超时单量
            attainmentRateData: res.data.data.map((item) => item.attainmentRate), //达标率
          };
          this.workOrderChart(params);
        })
        .catch(() => {
          this.workOrderLoading = false;
        });
    },
    //工单量-切换tab
    changeWorkOrderTab(item) {
      this.paramsWorkOrder.timeDimension = item.code;
      if (this.paramsWorkOrder.timeDimension == 'custom') {
        // 自定义
        this.type = 'workOrder';
        this.dialogVisible = true;
        this.dateRange = [];
      } else {
        this.dispatch();
      }
    },
    //自定义日期范围-提交
    submit() {
      if (this.dateRange.length == 0 || this.dateRange === null) {
        this.$message.error('请选择日期范围');
        return;
      }
      if (this.type == 'workOrder') {
        //工单量统计
        this.dispatch();
      }
      this.dialogVisible = false;
    },
    //工单量数据获取
    workOrderChart(params) {
      // 获取图表容器 DOM 元素
      const chartWorkOrder = this.$refs.chartWorkOrder;

      // 初始化 ECharts 实例
      const chart = echarts.init(chartWorkOrder);

      // 图表配置项
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            return `${params.seriesName}<br>${params.name}: ${params.value}`;
          },
        },
        legend: {
          data: ['派单量', '完单量', '超时单量', '达标率'],
        },
        xAxis: {
          type: 'category',
          data: params.Xdata,
        },
        yAxis: [
          {
            type: 'value',
            name: '柱状图值',
            position: 'left',
          },
          {
            type: 'value',
            name: '折线图值',
            position: 'right',
          },
        ],
        series: [
          {
            name: '派单量',
            data: params.dispatchData,
            type: 'bar',
            itemStyle: {
              color: '#5b89e5',
            },
            barCategoryGap: '10%', // 控制柱子组之间的间距
            barGap: '0%', // 控制同一组柱子之间的间距
          },
          {
            name: '完单量',
            data: params.completeData,
            type: 'bar',
            itemStyle: {
              color: '#58c5fa',
            },
            barCategoryGap: '10%', // 控制柱子组之间的间距
            barGap: '0%', // 控制同一组柱子之间的间距
          },
          {
            name: '超时单量',
            data: params.overtimeData,
            type: 'bar',
            itemStyle: {
              color: '#e44a3a',
            },
            barCategoryGap: '10%', // 控制柱子组之间的间距
            barGap: '0%', // 控制同一组柱子之间的间距
          },
          {
            name: '达标率',
            type: 'line',
            yAxisIndex: 1, // 使用第二个 yAxis
            data: params.attainmentRateData,
            itemStyle: {
              color: '#f49b0d',
            },
            lineStyle: {
              width: 2,
            },
            smooth: true,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表
      chart.setOption(option);

      // 监听窗口大小变化事件，调整图表尺寸
      window.addEventListener('resize', () => {
        chart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-cascader .el-input {
  width: 240px !important;
}
.content {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  .tit {
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
  }
  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    margin-bottom: 20px;
    .right {
      display: flex;
      p {
        font-size: 14px;
        line-height: 14px;
        color: #333;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .line {
          display: inline-block;
          width: 100%;
          height: 3px;
          background: #1890ff;
          border-radius: 2px;
          margin-top: 8px;
        }
      }
    }
  }
  .item {
    width: 1200px;
    .chart-container {
      width: 100%;
      height: 400px;
    }
  }
  .item:first-child {
    margin-right: 16px;
  }
}
</style>
