
import request from '@/http/request';

//结单统计
//工单量统计
export function dispatch (data) {
  return request({
    url: `/tickets/stats/dispatch`,
    method: 'POST',
    data
  });
}

//服务器台数统计
export function amount (data) {
  return request({
    url: `/tickets/stats/amount`,
    method: 'POST',
    data
  });
}

